#four {
  position: relative;

  .image-4 {
    background: url("../img/social_media.jpg") center center;
    background-size: cover; }


  .tools {
    position: relative;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    text-align: center;
    margin-top: 50px;

    .t1 {
      position: relative;
      width: 45%; }

    .t2 {
      position: relative;
      width: 45%; }

    .t3 {
      position: relative;
      width: 45%; }

    .t4 {
      position: relative;
      width: 45%; } }


  .tool-image {
    width: 100%;
    position: relative;
    height: 250px;
    margin-top: 50px; }

  .t1-im {
    background: url("../img/four-1.png") center center;
    background-size: cover; }

  .t2-im {
    background: url("../img/four-2.png") center center;
    background-size: cover; }

  .t3-im {
    background: url("../img/four-3.png")center center;
    background-size: cover; }

  .t4-im {
    background: url("../img/four-4.png") center center;
    background-size: 400px; } }



@media (max-width: 690px) {
  section#four {

    .tools {
      position: relative;
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      margin-top: 50px;
      flex-direction: column;

      .t1 {
        position: relative;
        width: 100%; }

      .t2 {
        position: relative;
        width: 100%; }

      .t3 {
        position: relative;
        width: 100%; }

      .t4 {
        position: relative;
        width: 100%; } } } }
