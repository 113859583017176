@charset "UTF-8";
@import url("https://fonts.googleapis.com/css?family=Fira+Sans:200,300,500,600,700");
* {
  font-size: 14px;
  line-height: 1.8; }
  @media (max-width: 768px) {
    * {
      font-size: 12px; } }

h1 {
  font-family: "Fira Sans", sans-serif;
  font-weight: 700;
  font-size: 6.5em;
  color: #F3943E;
  line-height: 1.2;
  position: absolute;
  top: 30%;
  left: 20%;
  transition: -20%; }

span {
  font-family: 'Fira Sans', sans-serif;
  font-weight: 300;
  font-size: 1em;
  color: white; }

h2 {
  font-family: 'Fira Sans', sans-serif;
  font-weight: 600;
  font-size: 1.6em;
  color: #454545;
  line-height: 1.2; }

h3 {
  font-family: 'Fira Sans', sans-serif;
  font-weight: 200;
  font-size: 2em;
  color: #454545;
  line-height: 1.2; }

p {
  font-family: 'Fira Sans', sans-serif;
  font-weight: 300;
  font-size: 1em;
  color: #454545; }

a {
  font-family: 'Fira Sans', sans-serif;
  font-weight: 300;
  font-size: 1em;
  color: #454545; }

strong {
  font-family: 'Fira Sans', sans-serif;
  font-weight: 500;
  font-size: 1em;
  color: #454545; }

@media (max-width: 1400px) {
  h1 {
    font-size: 4em;
    top: 35%; } }

@media (max-width: 1130px) {
  h1 {
    font-size: 3.75em;
    top: 35%; }
  h2 {
    font-size: 1.4em; } }

@media (max-width: 690px) {
  h1 {
    font-size: 2.5em;
    left: 10%;
    top: 25%; } }

body {
  background: white;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0 auto; }

section {
  z-index: 0; }

.container {
  width: 50%;
  max-width: 800px;
  margin: 0 auto;
  padding: 0 auto;
  z-index: 999;
  margin-top: 50px;
  margin-bottom: 80px; }

.container-tools {
  width: 80%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 auto;
  z-index: 999;
  margin-top: 50px;
  margin-bottom: 80px; }

.image {
  height: 95vh;
  width: 100%;
  position: relative; }

@media (max-width: 1400px) {
  .image {
    height: 70vh; }
  .container {
    width: 60%; } }

@media (max-width: 1130px) {
  .container {
    width: 80%;
    margin-top: 100px;
    margin-bottom: 100px; } }

@media (max-width: 815px) {
  .image {
    height: 50vh; } }

@media (max-width: 690px) {
  .image {
    height: 40vh; }
  .container {
    width: 85%;
    margin-top: 20px;
    margin-bottom: 50px; } }

.shape-top {
  position: absolute;
  top: -2px;
  left: 0;
  transform: rotate(-180deg);
  fill: white;
  pointer-events: none; }

.shape-bottom {
  position: absolute;
  bottom: -2px;
  right: 0;
  fill: white;
  pointer-events: none; }

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .shape-bottom, .shape-top {
    display: none; } }

#logo {
  position: relative;
  left: 0;
  top: 8%;
  width: 500px;
  max-height: 45px;
  align-items: center; }
  #logo img {
    width: 70%;
    max-height: 100%;
    cursor: pointer; }

nav {
  position: relative;
  top: 0px;
  margin: 0 auto;
  padding: 0 auto;
  width: 100vw;
  background: white;
  justify-content: center;
  align-items: center;
  z-index: 9999999999;
  height: 60px; }
  nav .nav-container {
    height: 100%;
    max-width: 1600px;
    position: relative;
    display: flex;
    margin: 0 auto;
    padding: 0 10px;
    justify-content: space-between;
    align-items: center; }
    nav .nav-container #burger {
      width: 30px;
      height: 30px;
      position: relative;
      cursor: pointer;
      display: none;
      transition: all 300ms ease; }
      nav .nav-container #burger #bars {
        position: absolute;
        top: 48%;
        width: 100%;
        height: 2px;
        background: #454545;
        transition: all 300ms ease;
        transition: -50%;
        left: 0;
        border-radius: 2px; }
        nav .nav-container #burger #bars:before {
          content: "";
          position: absolute;
          width: 100%;
          height: 100%;
          top: -9px;
          background: #454545;
          pointer-events: none;
          transition: all 300ms ease;
          left: 0;
          border-radius: 2px; }
        nav .nav-container #burger #bars:after {
          content: "";
          position: absolute;
          width: 100%;
          height: 100%;
          top: 9px;
          background: #454545;
          pointer-events: none;
          transition: all 300ms ease;
          left: 0;
          border-radius: 2px; }
      nav .nav-container #burger:hover #bars {
        background: #F3943E; }
        nav .nav-container #burger:hover #bars::before, nav .nav-container #burger:hover #bars:after {
          background: #F3943E; }
  nav .menu {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 80%;
    height: 100%;
    position: relative;
    margin: 0 auto;
    color: #454545;
    background: white; }
    nav .menu li {
      width: auto;
      margin: 0 30px;
      text-align: center;
      list-style-type: none;
      cursor: pointer !important; }
      nav .menu li a, nav .menu li span {
        cursor: pointer !important;
        font-weight: 300;
        font-family: 'Fira Sans', sans-serif;
        text-transform: uppercase;
        font-size: 1em;
        color: #454545;
        text-align: center;
        text-decoration: none;
        transition: all 300ms ease; }
      nav .menu li .nav {
        border-bottom: 2px solid transparent; }
        nav .menu li .nav:hover {
          border-bottom: 2px solid #F3943E; }
      nav .menu li .nav4 {
        border-bottom: 2px solid transparent; }
        nav .menu li .nav4:hover {
          border-bottom: 2px solid #F3943E; }
      nav .menu li .dropdownMenu {
        position: absolute;
        right: 240px;
        margin-top: 35px;
        top: -500px;
        padding: 0;
        height: 0;
        overflow: hidden;
        background: transparent;
        z-index: 9999999999999999999999999999999999999999;
        transition: all 100ms ease; }
        nav .menu li .dropdownMenu li {
          width: auto;
          margin: 0;
          padding: 0; }
          nav .menu li .dropdownMenu li a, nav .menu li .dropdownMenu li span {
            display: block;
            padding: 10px 20px;
            font-size: 1em;
            border-bottom: 1px solid #EFEFEF; }
            nav .menu li .dropdownMenu li a:hover, nav .menu li .dropdownMenu li span:hover {
              background: #F3943E;
              color: white; }
      nav .menu li.drop {
        padding: 10px 0; }
        @media (min-width: 750px) {
          nav .menu li.drop:hover .dropdownMenu {
            box-shadow: 0 3px 10px rgba(0, 0, 0, 0.3);
            display: block;
            height: auto;
            padding: 0px 0 0 0;
            width: 260px;
            top: 20px;
            right: 240px;
            background: white; }
            nav .menu li.drop:hover .dropdownMenu:before {
              content: '\25b2';
              position: relative;
              width: 100%;
              text-align: right; } }

@media (max-width: 1130px) {
  #logo {
    width: 400px; }
  nav {
    position: relative; }
    nav .menu {
      display: flex; }
      nav .menu li {
        margin: 0 15px; }
        nav .menu li a, nav .menu li span {
          font-size: 0.9em; }
        nav .menu li .dropdownMenu {
          position: absolute; }
          nav .menu li .dropdownMenu li {
            width: auto; }
            nav .menu li .dropdownMenu li a, nav .menu li .dropdownMenu li span {
              font-size: 0.85em; }
        nav .menu li.drop {
          padding: 10px 0; }
          nav .menu li.drop:hover .dropdownMenu {
            right: 90px; } }

@media (max-width: 750px) {
  #logo {
    width: 250px; }
  nav {
    height: 50px;
    z-index: 99999999;
    position: sticky; }
    nav .nav-container {
      position: relative; }
      nav .nav-container #burger {
        display: block;
        position: absolute;
        right: 15px;
        z-index: 99999999999999999999999999999; }
        nav .nav-container #burger.x #bars {
          background: transparent; }
          nav .nav-container #burger.x #bars:before {
            background: #454545;
            transform: rotate(-45deg);
            top: 0; }
          nav .nav-container #burger.x #bars:after {
            background: #454545;
            transform: rotate(45deg);
            top: 0; }
      nav .nav-container .menu {
        display: block;
        position: absolute;
        right: 0;
        top: 0;
        padding: 0;
        height: auto;
        width: 100%;
        top: -400px;
        transition: all 300ms ease; }
        nav .nav-container .menu li {
          margin: 0 auto;
          border-bottom: 1px solid #dcdcdc;
          width: 85%; }
          nav .nav-container .menu li:last-child {
            border-bottom: none;
            margin-bottom: 10px; }
          nav .nav-container .menu li a {
            width: 100%;
            height: 100%;
            display: block;
            text-align: left;
            padding: 10px 0 20px 0; }
          nav .nav-container .menu li span {
            width: 100%;
            height: 100%;
            display: block;
            text-align: left;
            padding: 0 0 10px 0; }
          nav .nav-container .menu li .nav:hover {
            border-bottom: none; }
          nav .nav-container .menu li .nav4:hover {
            border-bottom: none; }
          nav .nav-container .menu li.drop .nav4 {
            position: relative;
            cursor: pointer; }
            nav .nav-container .menu li.drop .nav4:before {
              content: '\25b6';
              position: absolute;
              right: 30px;
              top: 2px;
              font-size: 0.825em;
              transition: all 300ms ease; }
          nav .nav-container .menu li.drop .dropdownMenu {
            position: relative;
            right: 0;
            margin-top: 0;
            top: 0;
            padding: 0;
            height: 0;
            overflow: hidden;
            background: #EFEFEF;
            z-index: 999999999999999;
            transition: all 300ms ease;
            width: 100%;
            box-shadow: none; }
            nav .nav-container .menu li.drop .dropdownMenu:before {
              content: ''; }
            nav .nav-container .menu li.drop .dropdownMenu li {
              width: auto;
              margin: 0 auto;
              padding: 0 auto; }
              nav .nav-container .menu li.drop .dropdownMenu li a {
                display: block;
                text-transform: none;
                border-bottom: 1px solid #EFEFEF;
                font-size: 1em; }
                nav .nav-container .menu li.drop .dropdownMenu li a:hover {
                  background: #F3943E;
                  color: white; }
          nav .nav-container .menu li.drop.active {
            transition: all 300ms ease; }
            nav .nav-container .menu li.drop.active .nav4 {
              position: relative;
              cursor: pointer; }
              nav .nav-container .menu li.drop.active .nav4:before {
                content: '\25b6';
                position: absolute;
                top: 2px;
                font-size: 0.825em;
                transform: rotate(-90deg); }
            nav .nav-container .menu li.drop.active .dropdownMenu {
              transition: all 300ms ease;
              display: block;
              height: 100%;
              padding: 0;
              top: 0;
              right: 0;
              background: #EFEFEF;
              width: 100%;
              box-shadow: none; }
              nav .nav-container .menu li.drop.active .dropdownMenu:before {
                content: ''; }
        nav .nav-container .menu.open {
          top: 45px;
          background: white; } }

footer {
  position: relative;
  margin-top: 100px; }
  footer .footer-ul {
    position: relative;
    justify-content: center;
    max-width: 1400px;
    font-family: "Fira Sans", sans-serif;
    margin: 0 auto;
    text-align: center; }
    footer .footer-ul a {
      margin: 0px 50px 0px 0px;
      text-align: center;
      font-family: "Fira Sans", sans-serif;
      font-weight: 300;
      font-size: 1em;
      color: #454545;
      text-transform: uppercase;
      text-decoration: none;
      padding-top: 10px; }
      footer .footer-ul a:hover {
        border-bottom: 3px solid #F3943E; }

#header {
  position: relative; }
  #header .image-1 {
    height: 750px;
    width: 100%;
    position: relative;
    background: url("../img/header.jpg") center top 44%;
    background-size: cover; }

@media (max-width: 1400px) {
  section#header .image-1 {
    height: 550px; } }

@media (max-width: 690px) {
  section#header .image-1 {
    height: 300px; }
    section#header .image-1 h1 {
      padding-top: 50px; } }

#intro-section a {
  font-weight: 600; }

#intro-section table {
  margin: 30px 0; }
  #intro-section table td {
    font-size: 1em;
    font-family: 'Fira Sans',sans-serif;
    color: #454545;
    font-weight: 600;
    padding-right: 15px; }

#kontakt {
  position: relative; }
  #kontakt .image-2 {
    position: relative;
    max-width: 100%;
    display: block;
    margin: 0 auto; }
    #kontakt .image-2 .shop-slider {
      position: relative;
      margin: 0px auto;
      width: 100%;
      text-align: center;
      height: 95vh;
      z-index: 0;
      overflow: hidden; }
      #kontakt .image-2 .shop-slider #slide-1 {
        background: url("../img/shop-slideshow/20180406_113547.jpg") center center;
        background-size: cover;
        position: absolute;
        width: 100%;
        height: 100%; }
      #kontakt .image-2 .shop-slider #slide-2 {
        background: url("../img/shop-slideshow/161.jpg") center center;
        background-size: cover;
        position: absolute;
        width: 100%;
        height: 100%; }
      #kontakt .image-2 .shop-slider #slide-3 {
        background: url("../img/shop-slideshow/20190124_172843.jpg") center center;
        background-size: cover;
        position: absolute;
        width: 100%;
        height: 100%; }
      #kontakt .image-2 .shop-slider #slide-4 {
        background: url("../img/shop-slideshow/20180424_181254.jpg") center top 30%;
        background-size: cover;
        position: absolute;
        width: 100%;
        height: 100%; }
      #kontakt .image-2 .shop-slider #slide-5 {
        background: url("../img/shop-slideshow/20190124_093222.jpg") center top 30%;
        background-size: cover;
        position: absolute;
        width: 100%;
        height: 100%; }
      #kontakt .image-2 .shop-slider #slide-6 {
        background: url("../img/shop-slideshow/IMG-20180426-WA0005.jpg") center top 30%;
        background-size: cover;
        position: absolute;
        width: 100%;
        height: 100%; }
      #kontakt .image-2 .shop-slider #slide-7 {
        background: url("../img/shop-slideshow/20190124_154131.jpg") center top 30%;
        background-size: cover;
        position: absolute;
        width: 100%;
        height: 100%; }
      #kontakt .image-2 .shop-slider #slide-8 {
        background: url("../img/shop-slideshow/IMG-20180426-WA0006.jpg") center bottom 30%;
        background-size: cover;
        position: absolute;
        width: 100%;
        height: 100%; }
  @media (min-width: 691px) {
    #kontakt .oeffnungeszeiten {
      padding-right: 50px; } }
  #kontakt .oeffnungeszeiten {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    text-align: right;
    font-family: 'Fira Sans',sans-serif;
    color: #454545;
    font-weight: 300; }
    #kontakt .oeffnungeszeiten td {
      font-size: 1.2rem; }
  #kontakt .container {
    width: 100%; }
  #kontakt .container-map {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; }
    #kontakt .container-map .text-map {
      position: relative;
      width: 40%; }
      #kontakt .container-map .text-map p {
        text-align: right;
        padding-right: 50px;
        margin-top: 0;
        font-size: 1.4rem; }
    #kontakt .container-map .map {
      position: relative;
      width: 60%;
      height: 500px;
      justify-content: center;
      z-index: 99; }
      #kontakt .container-map .map #privacyProxyElement_1 {
        max-width: 100%; }
      #kontakt .container-map .map iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 0;
        z-index: 9; }

@media (max-width: 1400px) {
  section#kontakt {
    position: relative; }
    section#kontakt .image-2 {
      position: relative; }
      section#kontakt .image-2 .shop-slider {
        height: 70vh; } }

@media (max-width: 900px) {
  section#kontakt .container-map {
    width: 90%; }
    section#kontakt .container-map .text-map p {
      font-size: 1.2rem; } }

@media (max-width: 815px) {
  section#kontakt {
    position: relative; }
    section#kontakt .image-2 {
      position: relative; }
      section#kontakt .image-2 .shop-slider {
        height: 50vh; } }

@media (max-width: 690px) {
  section#kontakt .image-2 {
    position: relative; }
    section#kontakt .image-2 .shop-slider {
      height: 40vh; }
  section#kontakt .container-map {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column; }
    section#kontakt .container-map .text-map {
      width: 100%; }
      section#kontakt .container-map .text-map p {
        padding-right: 0px; }
    section#kontakt .container-map .map {
      margin-top: 50px;
      width: 100%;
      min-height: 350px; } }

/* Slider */
.slick-loading .slick-list {
  background: #fff url("./ajax-loader.gif") center center no-repeat; }

/* Icons */
@font-face {
  font-family: "slick";
  src: url("../js/slick/fonts/slick.eot");
  src: url("../js/slick/fonts/slick.eot?#iefix") format("embedded-opentype"), url("../js/slick/fonts/slick.woff") format("woff"), url("../js/slick/fonts/slick.ttf") format("truetype"), url("../js/slick/fonts/slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal; }

/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none;
  z-index: 9999999999999999999; }
  .slick-prev:hover, .slick-prev:focus,
  .slick-next:hover,
  .slick-next:focus {
    outline: none;
    background: transparent;
    color: transparent; }
    .slick-prev:hover:before, .slick-prev:focus:before,
    .slick-next:hover:before,
    .slick-next:focus:before {
      opacity: 1; }
  .slick-prev.slick-disabled:before,
  .slick-next.slick-disabled:before {
    opacity: 0.25; }
  .slick-prev:before,
  .slick-next:before {
    font-family: "slick";
    font-size: 20px;
    line-height: 1;
    color: white;
    opacity: 0.75;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }

.slick-prev {
  left: -25px;
  z-index: 9999999999999999999; }
  [dir="rtl"] .slick-prev {
    left: auto;
    right: -25px; }
  .slick-prev:before {
    content: "←"; }
    [dir="rtl"] .slick-prev:before {
      content: "→"; }

.slick-next {
  right: -25px; }
  [dir="rtl"] .slick-next {
    left: -25px;
    right: auto; }
  .slick-next:before {
    content: "→"; }
    [dir="rtl"] .slick-next:before {
      content: "←"; }

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px; }

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%; }
  .slick-dots li {
    position: relative;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer; }
    .slick-dots li button {
      border: 0;
      background: transparent;
      display: block;
      height: 20px;
      width: 20px;
      outline: none;
      line-height: 0px;
      font-size: 0px;
      color: transparent;
      padding: 5px;
      cursor: pointer; }
      .slick-dots li button:hover, .slick-dots li button:focus {
        outline: none; }
        .slick-dots li button:hover:before, .slick-dots li button:focus:before {
          opacity: 1; }
      .slick-dots li button:before {
        position: absolute;
        top: 0;
        left: 0;
        content: "•";
        width: 20px;
        height: 20px;
        font-family: "slick";
        font-size: 6px;
        line-height: 20px;
        text-align: center;
        color: black;
        opacity: 0.25;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale; }
    .slick-dots li.slick-active button:before {
      color: black;
      opacity: 0.75; }

#brillen {
  position: relative; }
  #brillen .image-3 {
    background: url("../img/matthew-hamilton-166549-unsplash.jpg") center center;
    background-size: cover; }
  #brillen .glasses {
    position: relative;
    width: 100%;
    text-align: center; }
    #brillen .glasses .brillen-slider {
      margin-top: 50px; }
      #brillen .glasses .brillen-slider .brille img {
        width: 70%; }
    #brillen .glasses .slick-next {
      right: 20px; }
    #brillen .glasses .slick-prev {
      left: 20px; }
    #brillen .glasses .sunbrillen-slider {
      margin-top: 50px; }
      #brillen .glasses .sunbrillen-slider .brille img {
        width: 70%; }
  #brillen .brands {
    position: relative;
    width: 100%;
    margin-top: 50px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center; }
    #brillen .brands .logo-main {
      flex: 0 0 30%;
      padding: 20px;
      cursor: pointer; }
      #brillen .brands .logo-main img {
        max-width: 100%;
        display: block;
        height: auto; }
      @media (min-width: 640px) {
        #brillen .brands .logo-main {
          flex: 0 0 20%;
          padding: 20px; } }

@media (max-width: 1130px) {
  section#brillen .brands {
    width: 100%; } }

@media (max-width: 690px) {
  section#brillen {
    position: relative; }
    section#brillen .glasses {
      position: relative;
      width: 95%;
      text-align: center; }
      section#brillen .glasses .brillen-slider {
        margin-top: 50px; }
    section#brillen .slick-next {
      right: 5px !important; }
    section#brillen .slick-prev {
      left: 5px !important; }
    section#brillen .sunglasses {
      margin-top: -100px; } }

#four {
  position: relative; }
  #four .image-4 {
    background: url("../img/social_media.jpg") center center;
    background-size: cover; }
  #four .tools {
    position: relative;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    text-align: center;
    margin-top: 50px; }
    #four .tools .t1 {
      position: relative;
      width: 45%; }
    #four .tools .t2 {
      position: relative;
      width: 45%; }
    #four .tools .t3 {
      position: relative;
      width: 45%; }
    #four .tools .t4 {
      position: relative;
      width: 45%; }
  #four .tool-image {
    width: 100%;
    position: relative;
    height: 250px;
    margin-top: 50px; }
  #four .t1-im {
    background: url("../img/four-1.png") center center;
    background-size: cover; }
  #four .t2-im {
    background: url("../img/four-2.png") center center;
    background-size: cover; }
  #four .t3-im {
    background: url("../img/four-3.png") center center;
    background-size: cover; }
  #four .t4-im {
    background: url("../img/four-4.png") center center;
    background-size: 400px; }

@media (max-width: 690px) {
  section#four .tools {
    position: relative;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-top: 50px;
    flex-direction: column; }
    section#four .tools .t1 {
      position: relative;
      width: 100%; }
    section#four .tools .t2 {
      position: relative;
      width: 100%; }
    section#four .tools .t3 {
      position: relative;
      width: 100%; }
    section#four .tools .t4 {
      position: relative;
      width: 100%; } }

#five {
  position: relative; }
  #five .image-5 {
    background: url("../img/sarah-doody-603348-unsplash.jpg") center top 50%;
    background-size: cover; }
  #five .news {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 20px; }
    #five .news .news-1 {
      position: relative;
      flex: 1 0 0;
      margin: 0 30px; }
    #five .news .news-2 {
      position: relative;
      flex: 1 0 0;
      margin: 0 30px; }
      @media (max-width: 700px) {
        #five .news .news-2 {
          margin: 0px; } }
  #five .news-image {
    width: 100%;
    position: relative;
    height: 250px; }
  #five .n1 {
    background: url("../img/n1.jpg");
    background-size: cover; }
  #five .n2 {
    background: url("../img/sync.jpg") center no-repeat;
    background-size: contain; }

@media (max-width: 1130px) {
  section#five .news {
    position: relative;
    margin-top: 100px; } }

@media (max-width: 690px) {
  section#five .news {
    flex-direction: column; }
    section#five .news .news-1 {
      width: 100%; }
    section#five .news .news-2 {
      width: 100%; } }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-t-ms-touch-action: pan-y;
  touch-action: pan-y;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
  height: 100%; }

.slick-list:focus {
  outline: none; }

.slick-list.dragging {
  cursor: pointer;
  cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  height: 100%;
  display: block; }

.slick-track:before,
.slick-track:after {
  display: table;
  content: ""; }

.slick-track:after {
  clear: both; }

.slick-loading .slick-track {
  visibility: hidden; }

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px; }

[dir='rtl'] .slick-slide {
  float: right; }

.slick-slide.slick-loading img {
  display: none; }

.slick-slide.dragging img {
  pointer-events: none; }

.slick-initialized .slick-slide {
  display: block; }

.slick-loading .slick-slide {
  visibility: hidden; }

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

.slick-prev:before,
.slick-next:before {
  font-family: 'slick';
  font-size: 35px;
  line-height: 1;
  opacity: .75;
  color: #F3943E;
  z-index: 99;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.slick-prev,
.slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  width: 50px !important;
  height: 50px !important;
  z-index: 9999999999999999999;
  padding: 0;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%); }

#impressum {
  margin-top: 150px;
  position: relative; }
  #impressum h1 {
    position: relative;
    top: 0;
    left: 0;
    padding-bottom: 50px; }

#datenschutz {
  margin-top: 150px;
  position: relative; }
  #datenschutz h1 {
    position: relative;
    top: 0;
    left: 0;
    padding-bottom: 50px; }

#online-tool .tool-container {
  margin-top: 300px;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  max-width: 1140px !important; }
  #online-tool .tool-container .section-inner {
    max-width: 600px;
    margin: 0 auto; }
  #online-tool .tool-container h3 {
    text-align: center;
    margin-top: 100px; }
  #online-tool .tool-container strong {
    text-align: center;
    margin-bottom: 150px;
    max-width: 600px; }

#online-tool #toenungs-simulator2 {
  width: 100%;
  max-width: 1140px;
  margin: 0 auto; }

#buttonContainer {
  max-width: 800px;
  margin: 60px auto 0; }
  #buttonContainer .btn-tools {
    appearance: none;
    border: 1px solid #e2e2e2;
    border-top: 1px solid #fcfcfc;
    border-left: 1px solid #fcfcfc;
    background: #EFEFEF;
    width: 230px;
    padding: 10px;
    margin: 5px;
    font-size: 0.925em;
    border-radius: 4px;
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.4);
    transition: all 300ms ease;
    cursor: pointer; }
    @media (max-width: 768px) {
      #buttonContainer .btn-tools {
        width: 180px; } }
    #buttonContainer .btn-tools:hover {
      box-shadow: 1px 0px 3px rgba(0, 0, 0, 0.4);
      background: #f4f4f4; }
    #buttonContainer .btn-tools.active {
      background: #F3943E;
      color: white;
      border: 1px solid #f28726;
      border-top: 1px solid #ffdd88;
      border-left: 1px solid #ffdd88; }
      #buttonContainer .btn-tools.active:hover {
        background: #f49948; }

#wett-sehtest, #glas-simulator, #glasdicken-simulator, #toenungs-simulator, #toenungs-simulator2 {
  display: none; }

@media (max-width: 750px) {
  #buttonContainer {
    display: none; } }

.cc_banner-wrapper {
  z-index: 999999999999999 !important; }
  .cc_banner-wrapper .cc_container {
    border-top: solid 2px #454545;
    background: #f2f2f2;
    color: #000;
    font-family: 'Fira Sans', sans-serif;
    box-sizing: border-box;
    font-size: 14px; }
    .cc_banner-wrapper .cc_container .cc_btn {
      background-color: #F3943E;
      -webkit-transition: background 200ms ease-in-out,color 200ms ease-in-out,-webkit-box-shadow 200ms ease-in-out;
      transition: background 200ms ease-in-out,color 200ms ease-in-out,-webkit-box-shadow 200ms ease-in-out;
      transition: background 200ms ease-in-out,color 200ms ease-in-out,box-shadow 200ms ease-in-out;
      transition: background 200ms ease-in-out,color 200ms ease-in-out,box-shadow 200ms ease-in-out,-webkit-box-shadow 200ms ease-in-out;
      -webkit-transition: background 200ms ease-in-out,color 200ms ease-in-out,box-shadow 200ms ease-in-out;
      border-radius: 5px;
      -webkit-border-radius: 5px;
      color: white;
      max-width: 140px; }

#orange-sticky {
  outline: none;
  box-sizing: border-box;
  z-index: 9999999999999999999999999999999999999; }
  #orange-sticky .sticky {
    position: fixed;
    width: 100px;
    height: 100px;
    right: 0;
    background: #F3943E;
    justify-content: center;
    cursor: pointer;
    align-items: center;
    display: inline-block; }
    #orange-sticky .sticky img {
      position: relative;
      left: 50%;
      top: 20px;
      transform: translate(-50%);
      max-width: 50px; }
    #orange-sticky .sticky p {
      color: white;
      text-align: center;
      padding-top: 5px; }
  #orange-sticky .termin {
    top: 150px; }
  #orange-sticky .mail {
    top: 275px; }
  #orange-sticky .telefon {
    top: 400px; }
    #orange-sticky .telefon .popuptext {
      display: none;
      width: 250px;
      background-color: #454545;
      color: white;
      font-size: 1.4em !important;
      text-align: center;
      padding: 15px 0;
      position: absolute;
      z-index: 1;
      top: 20%;
      left: -200%;
      margin-left: -80px;
      z-index: 999999999999999999999999999999999999999999999999999; }
      #orange-sticky .telefon .popuptext::after {
        content: "";
        position: absolute;
        z-index: 999999999999999999999999999999999999999999999999999;
        top: 27%;
        left: 100%;
        border-width: 15px;
        border-style: solid;
        border-color: transparent transparent transparent #454545; }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@media (max-width: 690px) {
  section#orange-sticky {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 55px;
    display: flex;
    background: #F3943E; }
    section#orange-sticky .flexbox {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      position: relative;
      justify-content: space-around; }
      section#orange-sticky .flexbox .orange-button {
        position: relative;
        bottom: 0;
        width: 32%;
        height: auto;
        display: block;
        align-items: center;
        justify-content: space-around; }
        section#orange-sticky .flexbox .orange-button a {
          width: 100%;
          height: 100%;
          display: block; }
        section#orange-sticky .flexbox .orange-button .sticky {
          position: relative;
          width: 100%;
          height: auto;
          right: auto;
          background: none;
          justify-content: center;
          cursor: pointer;
          align-items: center;
          display: block;
          text-align: center; }
          section#orange-sticky .flexbox .orange-button .sticky img {
            position: static;
            margin-top: 5px;
            max-width: 25px;
            margin-left: 25px; }
          section#orange-sticky .flexbox .orange-button .sticky p {
            color: white;
            text-align: center;
            padding-top: 0;
            margin: 0 auto;
            text-decoration: none;
            font-size: 0.8em; }
        section#orange-sticky .flexbox .orange-button .termin {
          width: 100%;
          text-align: center;
          top: 0;
          left: 0;
          right: auto; }
        section#orange-sticky .flexbox .orange-button .mail {
          width: 100%;
          top: 0;
          left: 0;
          right: auto;
          text-align: center; }
        section#orange-sticky .flexbox .orange-button .telefon {
          width: 100%;
          top: 0;
          left: 0;
          right: auto;
          text-align: center; } }

.aktionsswitch {
  display: none; }

#aktionen {
  position: relative; }
  #aktionen .image-aktion {
    background: url("../img/Aktionen.jpg") center top 50%;
    background-size: cover; }
  #aktionen .aktion {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 20px;
    margin-bottom: 0px; }
    #aktionen .aktion .aktionen-1 {
      position: relative;
      width: 100%;
      overflow: hidden;
      margin-right: 20px;
      flex: 1 0 55%; }
      #aktionen .aktion .aktionen-1 table {
        margin: 30px 0; }
        #aktionen .aktion .aktionen-1 table td {
          font-size: 1em;
          font-family: 'Fira Sans',sans-serif;
          color: #454545;
          font-weight: 300; }
      #aktionen .aktion .aktionen-1 p {
        padding: 0;
        margin: 0; }
      @media (max-width: 800px) {
        #aktionen .aktion .aktionen-1 .desktop {
          display: none; } }
      #aktionen .aktion .aktionen-1 img {
        max-width: 100%;
        margin-top: 00px; }
      #aktionen .aktion .aktionen-1 .stueckpreis {
        font-size: 0.8em; }
      #aktionen .aktion .aktionen-1 .brands {
        position: relative;
        margin-top: 50px;
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: center; }
        #aktionen .aktion .aktionen-1 .brands .logo-main {
          width: 125px;
          height: 82px;
          margin: 3px 10px; }
        #aktionen .aktion .aktionen-1 .brands .logo-1 {
          background: url("../img/brands/Ebene2.png") no-repeat center center;
          background-size: contain; }
        #aktionen .aktion .aktionen-1 .brands .logo-3 {
          background: url("../img/brands/Ebene10.png") no-repeat center center;
          background-size: contain; }
        #aktionen .aktion .aktionen-1 .brands .logo-2 {
          background: url("../img/brands/Ebene14.png") no-repeat center center;
          background-size: contain; }
    #aktionen .aktion .aktionen-2 {
      position: relative;
      flex: 1 0 40%;
      display: flex;
      justify-content: center; }
      #aktionen .aktion .aktionen-2 .mobil {
        display: none;
        margin-top: 10px; }
        @media (max-width: 800px) {
          #aktionen .aktion .aktionen-2 .mobil {
            display: block; } }
      #aktionen .aktion .aktionen-2 p {
        margin: 0;
        padding: 0; }
      #aktionen .aktion .aktionen-2 h2 {
        line-height: 1.5em;
        font-size: 1.3rem; }
        #aktionen .aktion .aktionen-2 h2 span {
          font-weight: 600;
          font-size: 1.5em !important; }
      #aktionen .aktion .aktionen-2 b {
        line-height: 1em;
        font-size: 1em; }
      #aktionen .aktion .aktionen-2 span {
        color: #454545; }
      #aktionen .aktion .aktionen-2 .overline {
        line-height: 0.5em; }
      #aktionen .aktion .aktionen-2 .stueckpreis {
        display: none;
        font-size: 0.8em; }
  #aktionen .aktion-image {
    width: 100%;
    position: relative;
    height: auto; }

@media (max-width: 1130px) {
  section#aktionen .aktion {
    position: relative;
    margin-top: 100px; } }

@media (max-width: 800px) {
  section#aktionen .aktion {
    flex-direction: column; } }
  @media all and (max-width: 800px) and (-ms-high-contrast: none), (max-width: 800px) and (-ms-high-contrast: active) {
    section#aktionen .aktion {
      flex-direction: row; } }

@media (max-width: 800px) {
    section#aktionen .aktion .aktionen-1 {
      width: 100% !important;
      overflow: hidden; }
      section#aktionen .aktion .aktionen-1 .stueckpreis {
        display: none; }
    section#aktionen .aktion .aktionen-2 {
      width: 100%; }
      section#aktionen .aktion .aktionen-2 .stueckpreis {
        display: block;
        font-size: 0.8em;
        line-height: 0em; } }

.Aktion2020 > div {
  overflow: visible !important; }

@media (max-width: 767px) {
  .Aktion2020 {
    margin-bottom: 150px !important;
    margin-top: 50px !important; } }

#infoOne {
  position: relative; }
  #infoOne .image-1 {
    height: 750px;
    width: 100%;
    position: relative;
    background: url("../img/info/header.jpg") center top 38%;
    background-size: cover; }
  #infoOne .info {
    display: flex;
    flex-wrap: wrap; }
    #infoOne .info .col-1 {
      flex: 1 0 0;
      min-width: 300px;
      margin: 10px; }
      #infoOne .info .col-1 img {
        width: 100%;
        max-width: 350px;
        margin-top: 20px; }

@media (max-width: 1400px) {
  section#header .image-1 {
    height: 550px; } }

@media (max-width: 690px) {
  section#header .image-1 {
    height: 300px; }
    section#header .image-1 h1 {
      padding-top: 00px; } }

.skyline__modal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.9);
  padding: 20px;
  display: flex;
  align-items: center;
  overflow-y: auto; }
  .skyline__modal-content {
    background: white;
    width: 100%;
    padding: 0;
    max-width: 800px;
    margin: 0 auto;
    max-height: 100%; }
    .skyline__modal-content .close {
      display: inline-block;
      margin-left: auto;
      font-size: 16px;
      padding: 10px;
      cursor: pointer; }
    .skyline__modal-content .skyline__lightbox-slider img {
      width: 100%;
      height: auto;
      display: block; }

* {
  outline: none;
  box-sizing: border-box;
  text-decoration: none; }

body {
  overflow-x: hidden; }
