.cc_banner-wrapper {
  z-index: 999999999999999!important;
  .cc_container {
    border-top: solid 2px $gray;
    background: #f2f2f2;
    color: #000;
    font-family: 'Fira Sans', sans-serif;
    box-sizing: border-box;
    font-size: 14px;
    .cc_btn {
      background-color: $orange;
      -webkit-transition: background 200ms ease-in-out,color 200ms ease-in-out,-webkit-box-shadow 200ms ease-in-out;
      transition: background 200ms ease-in-out,color 200ms ease-in-out,-webkit-box-shadow 200ms ease-in-out;
      transition: background 200ms ease-in-out,color 200ms ease-in-out,box-shadow 200ms ease-in-out;
      transition: background 200ms ease-in-out,color 200ms ease-in-out,box-shadow 200ms ease-in-out,-webkit-box-shadow 200ms ease-in-out;
      -webkit-transition: background 200ms ease-in-out,color 200ms ease-in-out,box-shadow 200ms ease-in-out;
      border-radius: 5px;
      -webkit-border-radius: 5px;
      color: white;
      max-width: 140px; } } }
