@import "includes/vars";
@import "includes/fonts";
@import "includes/navigation";
@import "includes/shape";
@import "includes/header";
@import "includes/footer";
@import "includes/one";
@import "includes/two";
@import "includes/slick-theme";
@import "includes/three";
@import "includes/four";
@import "includes/five";
@import "includes/slick";
@import "includes/impressum";
@import "includes/datenschutz";
@import "includes/online-tool";
@import "includes/cookie";
@import "includes/orange-sticky";
@import "includes/aktionen";
@import "includes/info";
@import "includes/lightbox";


* {
	outline: none;
	box-sizing: border-box;
	text-decoration: none; }
body {
	overflow-x: hidden; }
