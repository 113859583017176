.aktionsswitch {
  display: none; }


#aktionen {
  position: relative;

  .image-aktion {
    background: url("../img/Aktionen.jpg") center top 50%;
    background-size: cover; }


  .aktion {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 20px;
    margin-bottom: 0px;


    .aktionen-1 {
      position: relative;
      width: 100%;
      // max-width: 700px
      overflow: hidden;
      margin-right: 20px;
      flex: 1 0 55%;
      table {
        margin: 30px 0;
        td {
          font-size: 1em;
          font-family: 'Fira Sans',sans-serif;
          color: #454545;
          font-weight: 300; } }
      p {
        padding: 0;
        margin: 0; }
      .desktop {
        @media (max-width: 800px) {
          display: none; } }
      img {
        max-width: 100%;
        margin-top: 00px; }
      .stueckpreis {
        font-size: 0.8em; }
      .brands {
        position: relative;

        margin-top: 50px;
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        .logo-main {
          width: 125px;
          height: 82px;
          margin: 3px 10px; }
        .logo-1 {
          background: url("../img/brands/Ebene2.png") no-repeat center center;
          background-size: contain; }
        .logo-3 {
          background: url("../img/brands/Ebene10.png") no-repeat center center;
          background-size: contain; }
        .logo-2 {
          background: url("../img/brands/Ebene14.png") no-repeat center center;
          background-size: contain; } } }

    .aktionen-2 {
      position: relative;
      // width: 47%
      flex: 1 0 40%;
      display: flex;
      // flex-direction: column
      justify-content: center;
      .mobil {
        display: none;
        margin-top: 10px;
        @media (max-width: 800px) {
          display: block; } }
      p {
        margin: 0;
        padding: 0; }
      h2 {
        line-height: 1.5em;
        font-size: 1.3rem;
        span {
          font-weight: 600;
          font-size: 1.5em!important; } }
      b {
        line-height: 1em;
        font-size: 1em; }
      span {
        // color: #F3943E
        color: #454545; }
      //   font-size: 1.8em!important
      //   line-height: 1em
      .overline {
        line-height: 0.5em; }
      .stueckpreis {
        display: none;
        font-size: 0.8em; } } }





  .aktion-image {
    width: 100%;
    position: relative;
    height: auto;
    // @media (max-width: 400px)
 }    //   height: 200px

  .n1 {
    // background: url(../img/aktion.png) no-repeat
 } }    // background-size: contain



@media (max-width: 1130px) {
  section#aktionen {

    .aktion {
      position: relative;
      margin-top: 100px; } } }



@media (max-width: 800px) {
  section#aktionen {


    .aktion {
      flex-direction: column;
      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        flex-direction: row; }

      .aktionen-1 {
        width: 100%!important;
        overflow: hidden;
        .stueckpreis {
          display: none; } }


      .aktionen-2 {
        width: 100%;
        .stueckpreis {
          display: block;
          font-size: 0.8em;
          line-height: 0em; } } } } }
.Aktion2020 {
  & > div {
    overflow: visible !important; } }
@media (max-width: 767px) {
  .Aktion2020 {
    margin-bottom: 150px !important;
    margin-top: 50px !important; } }


