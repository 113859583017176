#header {
  position: relative;

  .image-1 {
    height: 750px;
    width: 100%;
    position: relative;
    background: url("../img/header.jpg") center top 44%;
    background-size: cover; } }



@media (max-width: 1400px) {
  section#header {

    .image-1 {
      height: 550px; } } }



@media (max-width: 690px) {
  section#header {

    .image-1 {
      height: 300px;
      h1 {
        padding-top: 50px; } } } }


#intro-section {
  a {
    font-weight: 600; }
  table {
    margin: 30px 0;
    td {
      font-size: 1em;
      font-family: 'Fira Sans',sans-serif;
      color: #454545;
      font-weight: 600;
      padding-right: 15px; } } }
