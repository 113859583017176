// #online-tool
//   position: relative
//   .container-tools
//     h3
//       text-align: center
//     p
//       text-align: center




#online-tool {
  .tool-container {
    margin-top: 300px;
    width: 100%;
    margin: 0 auto;
    text-align: center;
    max-width: 1140px!important;
    .section-inner {
      max-width: 600px;
      margin: 0 auto; }

    h3 {
      text-align: center;
      margin-top: 100px; }
    strong {
      text-align: center;
      margin-bottom: 150px;
      max-width: 600px; } }


  #toenungs-simulator2 {
    width: 100%;
    max-width: 1140px;
    margin: 0 auto; } }

#buttonContainer {
  max-width: 800px;
  margin: 60px auto 0;
  .btn-tools {
    appearance: none;
    border: 1px solid darken($light-gray, 5%);
    border-top: 1px solid #fcfcfc;
    border-left: 1px solid #fcfcfc;
    background: $light-gray;
    width: 230px;
    padding: 10px;
    margin: 5px;
    font-size: 0.925em;
    border-radius: 4px;
    box-shadow: 1px 1px 5px rgba(black, 0.4);
    transition: all 300ms ease;
    cursor: pointer;
    @media (max-width: 768px) {
      width: 180px; }
    &:hover {
      box-shadow: 1px 0px 3px rgba(black, 0.4);
      background: lighten($light-gray, 2%); }
    &.active {
      background: $orange;
      color: white;
      border: 1px solid darken($orange, 5%);
      border-top: 1px solid #ffdd88;
      border-left: 1px solid #ffdd88;
      &:hover {
        background: lighten($orange, 2%); } } } }

#wett-sehtest, #glas-simulator, #glasdicken-simulator, #toenungs-simulator, #toenungs-simulator2 {
  display: none; }


@media (max-width: 750px) {
  #buttonContainer {
    display: none; } }
